export const INITIAL_ORDER = "INITIAL_ORDER";

export const SPINNING = "SPINNING";

export const RESET_ORDER = "RESET_ORDER";

export const EXCHANGE_RATE = "EXCHANGE_RATE";

export const EXCHANGE_RATE_SPINNING = "EXCHANGE_RATE_SPINNING";

export const SHOW_REVIEW = "SHOW_REVIEW";

export const REVIEW_DATA = "REVIEW_DATA";

export const SUBMIT_LOADING = "SUBMIT_LOADING";

export const SHOW_SUBMIT_RESULT_DIALOG = "SHOW_SUBMIT_RESULT_DIALOG";

export const RESET_ORDER_STORE = "RESET_ORDER_STORE";

export const SUBMIT_RESULT = "SUBMIT_RESULT";

export const INITIAL_SETTINGS = "INITIAL_SETTINGS";

export const SHOW_EXIST_MESSAGE = "SHOW_EXIST_MESSAGE";

export const MODIFY_ORIGINAL_ORDER = "MODIFY_ORIGINAL_ORDER";

export const GET_RECEIVERS = "GET_RECEIVERS";

export const SET_RECEIVER_BY_SELECTION = "SET_RECEIVER_BY_SELECTION";