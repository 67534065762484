export const SET_TABLE_DATA = "SET_TABLE_DATA";

export const SHOW_TABLES = "SHOW_TABLES";

export const SET_TABLE_SPINNING = "SET_TABLE_SPINNING";

export const LATEST_PACKAGES_SPINNING = "LATEST_PACKAGES_SPINNING";

export const LATEST_PACKAGES = "LATEST_PACKAGES";

export const PDF_LOADING = "PDF_LOADING";

export const SET_PDF_FILE = "SET_PDF_FILE";

export const SHOW_MODAL = "SHOW_MODAL";
